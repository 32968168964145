<template>
  <section class="intro-x">
    <div class="w-full flex my-5 items-center">
      <back-button />
      <h1 class="font-bold capitalize text-xl ml-2">Carga masiva</h1>
    </div>
    <div class="w-full grid gap-4 grid-cols-1 lg:grid-cols-2">
      <div @click="handleExcelDownload" class="text-center bg-green-500 py-6 rounded-md uppercase font-bold flex justify-center items-center text-white cr-pointer">
        <div>
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M17,13L12,18L7,13H10V9H14V13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
          </svg>
        </div>
        <div class="ml-2 relative" style="top:2px;">exportar plantilla </div>
      </div>
      <div @click="displayModal=true" class="text-center bg-yellow-500 py-6 rounded-md uppercase font-bold flex justify-center items-center text-white cr-pointer">
        <div>
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
          </svg>
        </div>
        <div class="ml-2 relative" style="top:2px;">Importar plantilla </div>
      </div>
    </div>
    <div class="mt-5" v-show="getInconsisData.length">
      <p>Errores de la plantilla</p>
    </div>
    <div class="w-full mb-5" v-show="getInconsisData.length">
      <DataTable :value="getInconsisData">
        <Column header="Fila" field="field" headerStyle="width:30%;" />
        <Column header="Campo" field="column" headerStyle="width:30%;" />
        <Column header="Mensaje" field="error" />
      </DataTable>
    </div>
    <div class="mt-5">
      <p>Consideraciones</p>
    </div>
    <div class="w-full mb-5">
      <DataTable :value="getConsiderations">
        <Column header="Campo" field="campo" headerStyle="width:30%;" />
        <Column header="Descripción" field="descripcion" />
      </DataTable>
    </div>
    <Dialog header="Importar Plantilla" v-model:visible="displayModal" :style="{width: '50vw'}" :modal="true">
      <div class="w-full grid grid-cols-1 lg:grid-cols-1 gap-1 mb-5">
        <div class="mb-3">
          <label for="supplierId">Plantilla excel</label>
          <input id="upfile" type="file" @change="model.adjunto=$event.target.files[0]" class="form-control"/>
          <messageError :text="errors.adjunto" />
        </div>
      </div>
      <template #footer>
        <Button @click="displayModal=false" label="Cancelar" icon="pi pi-times" class="p-button-text"/>
        <Button @click="onSubmit" label="Importar" icon="pi pi-check" autofocus />
      </template>
    </Dialog>
  </section>
</template>

<script>
import suppliersFindName from '../../../../services/suppliersFindName'
import suppliersImportTemplate from '../../../../services/suppliersImportTemplate'
import { computed, ref, onMounted, reactive } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import { messageSuccess } from '../../../../../../../libs/mensajes'
import { useForm, useField } from 'vee-validate'
import { object, mixed } from 'yup'
export default {
  name: 'CargaMasiva',
  components: {
    DataTable,
    Column,
    Dialog
  },
  setup () {
    const filteredSuppliers = ref([])
    const displayModal = ref(false)
    const considerations = reactive({
      total: 0,
      page: 1,
      data: [
        { campo: 'id', descripcion: 'Código del producto y presentacion' },
        { campo: 'Producto', descripcion: 'Nombre del producto' },
        { campo: 'Precio', descripcion: 'Precio del producto' },
        { campo: 'Código Producto', descripcion: 'Código del producto' }
      ]
    })
    const inconsisData = reactive({
      total: 0,
      page: 1,
      data: []
    })
    const getConsiderations = computed(() => {
      return considerations.data
    })
    const getInconsisData = computed(() => {
      return inconsisData.data
    })
    const validationSchema = object().shape({
      adjunto: mixed().required().test('fileFormat', 'Formato Excel', (value) => {
          return value && ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(value.type)
        })
    })
    const { values: model, handleSubmit, handleReset, errors } = useForm({
      validationSchema
    })
    useField('adjunto', null, { initialValue: null })
    useField('detail', null, { initialValue: null })
    useField('quotesName', null, { initialValue: null })

    const searchSuppliers = ({ query }) => {
      if (!query.trim() || query.length < 3) {
        filteredSuppliers.value = []
      } else {
        return suppliersFindName({ query: query.trim() }).then(({ data }) => {
          filteredSuppliers.value = data.data
        }).catch(err => {
          console.error(err.message)
        })
      }
    }

    const onSubmit = handleSubmit((values) => {
      console.log(1234)
      var formData = new FormData()
      formData.append('files', model.adjunto)
      displayModal.value = false
      inconsisData.data = []
      suppliersImportTemplate(formData).then(({ status, data }) => {
        if (data.data.type === 0) {
          inconsisData.data = data.data.errors
          return false
        }
        if (status === 201) {
          messageSuccess('Plantilla importada')
          return handleReset()
        }
      }).catch(err => {
        console.error(err.message)
      })
      // Take the file object
    })

    const handleExcelDownload = () => {
      window.open(`${process.env.VUE_APP_MICROSERVICE_API_SHOPS}/suppliers/export-template-xls`, '_blank')
    }

    onMounted(() => {
      setTimeout(() => {
        handleReset()
      }, 200)
    })
    return {
      model,
      errors,
      getConsiderations,
      getInconsisData,
      handleExcelDownload,
      onSubmit,
      filteredSuppliers,
      searchSuppliers,
      displayModal

    }
  }

}
</script>

<style scoped>
.cr-pointer{
  cursor: pointer;
}
</style>
